import React from "react";
import FormPage from "./components/FormPage";
import VideoPage from "./components/VideoPage";
import { useAuthContext } from "./context/AuthContext";
const App = () => {
  const { user } = useAuthContext();
  return (
    <div className="screenHeight overflow-auto max-w-md w-full mx-auto bg-slate-100">
      {!user ? <FormPage /> : <VideoPage />}
    </div>
  );
};

export default App;
