import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  // useEffect,
} from "react";
import { AdvancedVideo } from "@cloudinary/react";
import axios from "axios";
import headingIMG from "./../assets/Top_Heading_Two Line.svg";
import { FaCamera } from "react-icons/fa";
import recGif from "./../assets/rec.gif";
import clickSound from "./../assets/clickSound.mp3";
import LoaderScreen from "./LoaderScreen";
import { setVideoFormat } from "./setVideoFormat";
const VideoPage = () => {
  const [loading, setLoading] = useState(false);
  const [camIsStarted, setCamIsStarted] = useState(false);
  const [startRecording, setStartRecording] = useState(false);
  const [stopRecording, setStopRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [getVideo, setGetVideo] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [shouldFaceUser, setShouldFaceUser] = useState("user");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const headingSVG = useRef(null);
  const [pid, setPid] = useState(null);
  let chunks = [];

  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  useEffect(() => {
    let interval = null;
    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  let clickMusic = new Audio(clickSound);

  const constraints = useMemo(() => {
    return {
      audio: {
        channelCount: 1,
        sampleRate: 16000,
        sampleSize: 16,
        volume: 1,
      },
      video: {
        height: 720,
        width: 1280,
        facingMode: shouldFaceUser,
      },
    };
  }, [shouldFaceUser]);

  const draw = useCallback(() => {
    if (canvasRef.current !== null) {
      let ctx = canvasRef.current.getContext("2d");
      ctx.canvas.width = videoRef.current.clientWidth;
      ctx.canvas.height = videoRef.current.clientHeight;
      ctx.scale(-1, 1);
      ctx.translate(-ctx.canvas.width, 0);
      ctx.drawImage(
        videoRef.current,
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.beginPath();
      ctx.fillStyle = "#d92732";
      ctx.fillRect(0, 0, ctx.canvas.width, 110);
      ctx.fillRect(0, 0, 30, ctx.canvas.height);
      ctx.fillRect(ctx.canvas.width - 30, 30, 30, ctx.canvas.height);
      ctx.fillRect(0, ctx.canvas.height - 30, ctx.canvas.width, 30);
      ctx.drawImage(
        headingSVG.current,
        (ctx.canvas.width - 200) / 2,
        5,
        200,
        101
      );

      requestAnimationFrame(draw);
    }
  }, []);
  const openCam = useCallback(async () => {
    setLoading(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setCamIsStarted(true);
      await navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function success(stream) {
          setMediaStream(stream);
          videoRef.current.srcObject = stream;
          videoRef.current.muted = true;
          videoRef.current.play();
          if (!stopRecording) {
            draw();
            let audioTrack = stream.getAudioTracks()[0];
            let videoStream = canvasRef.current.captureStream(30);
            let videoTrack = videoStream.getVideoTracks()[0];
            let finalStream = new MediaStream([videoTrack, audioTrack]);
            setMediaRecorder(new MediaRecorder(finalStream));
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(
            "something went wrong. unable to access your camera or your device is incompatible to this app. " +
              err.message
          );
        });
    }
  }, [constraints, draw, stopRecording]);

  // flip camera
  const changeCamera = () => {
    if (mediaRecorder) {
      chunks = [];
      mediaStream.getVideoTracks()[0].stop();
      mediaStream.getAudioTracks()[0].stop();
      setMediaRecorder(null);
      // setShouldFaceUser(!shouldFaceUser);
      if (constraints.video.facingMode === "user") {
        setShouldFaceUser("environment");
      } else {
        setShouldFaceUser("user");
      }
    }
    openCam();
  };

  // start recording
  const actionRecording = () => {
    if (mediaRecorder) {
      setTimerOn(true);
      clickMusic.play();
      setShowGif(true);
      setStartRecording(true);
      mediaRecorder.start();
      // let vid = document.createElement("video");
      // vid.id = "recordedVideo";
      // vid.muted = false;
      // vid.autoplay = true;
      // vid.volume = 1.0;
      // vid.setAttribute("webkit-playsinline", "webkit-playsinline");
      // vid.setAttribute("playsinline", "playsinline");
      // vid.controls = true;
      // vid.style.display = "none";
      // createVid.current.appendChild(vid);
    } else {
      alert("Please start camera");
    }
  };
  // stop recording
  const actionStopRecording = async () => {
    let vidUrl = "";
    setLoading(true);
    if (mediaRecorder) {
      setTimerOn(false);
      clickMusic.play();
      setShowGif(false);
      mediaRecorder.stop();
      mediaRecorder.onstop = async (ev) => {
        // document.getElementById("recordedVideo").style.display = "block";
        let blob = new Blob(chunks, { type: "video/mkv;" });
        chunks = [];
        // setVideoURL(window.URL.createObjectURL(blob));
        vidUrl = window.URL.createObjectURL(blob);
        // if (document.getElementById("recordedVideo")) {
        //   document.getElementById("recordedVideo").src =
        //     window.URL.createObjectURL(blob);
        // }
        const file = await fetch(vidUrl)
          .then((r) => r.blob())
          .then(
            (blobFile) => new File([blobFile], "videofile", { type: "mkv" })
          );

        var fd = new FormData();
        fd.append("file", file);
        fd.append("upload_preset", "wqurcz5l");
        fd.append("api_key", "476749989496349");
        var url = `https://api.cloudinary.com/v1_1/sachind3/upload`;
        const config = {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json, */*",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            // Do whatever you want with the native progress event
            // console.log('progressEvent', progressEvent);
            //   var progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
            //   document.getElementById('progress').style.width = progress + "%";

            console.log(`onUploadProgress progressEvent.loaded: ${progressEvent.loaded},
        progressEvent.total: ${progressEvent.total}`);
          },
        };
        await axios
          .post(url, fd, config)
          .then((response) => {
            // const data = response.data;
            setPid(response.data.public_id);
            setLoading(false);
            setGetVideo(true);
          })
          .catch((err) => console.log(err));
      };
    } else {
      setLoading(false);
      alert("No recording found");
    }
    mediaRecorder.ondataavailable = function (ev) {
      chunks.push(ev.data);
    };
    mediaStream.getVideoTracks()[0].stop();
    mediaStream.getAudioTracks()[0].stop();

    setStopRecording(true);
    setMediaRecorder(null);
  };
  // draw canvas

  // reload frame
  const actionRetake = () => {
    window.location.reload();
  };
  // download recorded video
  const actionDownload = () => {
    const downloadVideo = document.getElementById("downloadVideo");
    const link = document.createElement("a");
    link.href = downloadVideo.children[0].children[1].src;
    link.setAttribute("download", "video.mp4");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {loading && <LoaderScreen />}

      <div
        className={`flex items-center justify-center h-full px-4 ${
          camIsStarted && "bg-[#d62733]"
        }`}
      >
        {!camIsStarted ? (
          <div className="flex flex-col gap-4 items-center justify-center text-center">
            <p className="font-bold text-xl">
              Click to enable camera for recording
            </p>
            <button
              type="button"
              onClick={openCam}
              className="text-white py-3 px-8 leading-4 shadow-md bg-red-500 rounded font-semibold text-xl flex items-center justify-center gap-3"
            >
              <FaCamera className="w-8" /> Open Camera
            </button>
          </div>
        ) : (
          <>
            <div className="relative">
              <div className="hidden">
                <img src={headingIMG} alt="headingImg" ref={headingSVG} />
              </div>
              <div className="relative">
                {!stopRecording && (
                  <>
                    {showGif && (
                      <>
                        <div className="absolute z-[1] top-2 left-2 w-14">
                          <img src={recGif} alt="recordingGif" />
                        </div>
                        <div className="absolute z-[1] top-2 right-3 text-white">
                          <span>
                            {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
                          </span>
                          <span>
                            {("0" + Math.floor((time / 1000) % 60)).slice(-2)}:
                          </span>
                          <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
                        </div>
                      </>
                    )}

                    <video
                      id="video"
                      playsInline
                      ref={videoRef}
                      className="w-full"
                    ></video>
                    <canvas
                      id="canvas"
                      ref={canvasRef}
                      className="absolute top-0 left-0 w-full"
                    ></canvas>
                  </>
                )}
                {getVideo && (
                  <div id="downloadVideo">
                    <AdvancedVideo
                      cldVid={setVideoFormat(pid)}
                      className="w-full"
                      id={pid}
                      controls={true}
                      autoPlay={true}
                      playsInline="playsInline"
                      webkit-playsinline="webkit-playsinline"
                      muted={false}
                    />
                  </div>
                )}
              </div>
            </div>

            {!startRecording && !stopRecording && (
              <div className="fixed z-[1] bottom-0 left-0 right-0 w-full py-2 px-4 bg-black/30 flex justify-center gap-4 text-sm text-white items-center">
                <button
                  className="btn"
                  onClick={actionRecording}
                  style={{ gridColumnStart: 2 }}
                >
                  Start Recording
                </button>
                <button className="btn" onClick={changeCamera}>
                  Flip Camera
                </button>
              </div>
            )}
            {startRecording && !stopRecording && (
              <div className="fixed z-[1] bottom-0 left-0 right-0 w-full py-2 px-4 bg-black/30 grid justify-center gap-4 text-sm text-white items-center">
                <button className="btn" onClick={actionStopRecording}>
                  Stop Recording
                </button>
              </div>
            )}
            {startRecording && stopRecording && (
              <div className="fixed z-[1] bottom-0 left-0 right-0 w-full py-2 px-4 bg-black/30 flex justify-center gap-4 text-sm text-white items-center">
                <button
                  className="bg-red-500 py-2 px-3 leading-4 shadow-md"
                  onClick={actionRetake}
                >
                  Retake
                </button>
                <button
                  className="bg-red-500 py-2 px-3 leading-4 shadow-md"
                  onClick={actionDownload}
                >
                  Download
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default VideoPage;
