import { Cloudinary } from "@cloudinary/url-gen";

export function setVideoFormat(pid) {
  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "sachind3",
    },
  });

  // Use the image with public ID, 'docs/shoes'.
  const myImage = cld.video(pid);

  // Set the format to GIF.
  myImage.format("mp4");

  return myImage;
}
