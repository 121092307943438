import React, { useState } from "react";
import { useAuthContext } from "../context/AuthContext";
// import WHDLOGO from "../assets/WHD_Heart.svg";
import SUNLOGO from "../assets/sun-pharma-logo.svg";
import { FaHeartBroken, FaHeart } from "react-icons/fa";
const FormPage = () => {
  const { setUser } = useAuthContext();
  const [userData, setUserData] = useState({
    name: "john smith",
    email: "john@gmail.com",
    country: "india",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !userData.name.trim() ||
      !userData.email.trim() ||
      !userData.country.trim()
    ) {
      alert("Please provide all the fields");
    }
    setUser(userData);
  };
  return (
    <div className="p-4 flex items-center justify-center h-full flex-col  relative">
      <div className="flex flex-col items-center justify-center gap-6 mt-auto pt-4">
        {/* <img src={WHDLOGO} className="w-44" alt="world heart day" /> */}
        <div className="px-4 leading-5 text-center ">
          <div>
            This <span className="text-red-600 font-bold">World Heart Day</span>
            , create a special message for your patients and the general public
            to
          </div>
          <div className="font-bold">
            #USE <FaHeart className="inline-block text-red-600" /> to beat{" "}
            <FaHeartBroken className="inline-block text-red-600" />{" "}
            (cardiovascular disease)
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="max-w-[320px] w-full mx-auto bg-white p-4 shadow-md rounded mt-3"
        >
          <div className="relative w-full mb-3">
            <label
              htmlFor="name"
              className="text-sm leading-6 font-medium text-slate-600 group-focus-within:text-red-500 mb-1 inline-block"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              className="focus:ring-2 focus:ring-red-500 focus:outline-none text-lg appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 py-2 px-3 ring-1 ring-slate-200 shadow-sm rounded"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              htmlFor="email"
              className="text-sm leading-6 font-medium text-slate-600 group-focus-within:text-red-500 mb-1 inline-block"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="focus:ring-2 focus:ring-red-500 focus:outline-none text-lg appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 py-2 px-3 ring-1 ring-slate-200 shadow-sm rounded"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              htmlFor="country"
              className="text-sm leading-6 font-medium text-slate-600 group-focus-within:text-red-500 mb-1 inline-block"
            >
              Country
            </label>
            <input
              type="text"
              id="country"
              className="focus:ring-2 focus:ring-red-500 focus:outline-none text-lg appearance-none w-full leading-6 text-slate-900 placeholder-slate-400 py-2 px-3 ring-1 ring-slate-200 shadow-sm rounded"
              value={userData.country}
              onChange={(e) =>
                setUserData({ ...userData, country: e.target.value })
              }
            />
          </div>
          <button
            type="submit"
            className="bg-red-600 shadow text-white text-sm leading-6 font-medium py-2 px-3 disabled:opacity-50 rounded block w-full"
            disabled={
              !userData.name.trim() ||
              !userData.email.trim() ||
              !userData.country.trim()
            }
          >
            Submit
          </button>
        </form>
      </div>
      <img src={SUNLOGO} alt="sun logo" className="w-12 h-12 mt-auto ml-auto" />
    </div>
  );
};

export default FormPage;
