import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const LoaderScreen = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 text-center flex items-center justify-center z-[100] bg-white flex-col gap-4">
      <ClipLoader color="red" size={60} />
      Loading ...
    </div>
  );
};

export default LoaderScreen;
